import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TableCell, TableRow, Tooltip } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'


const localUrl = process.env.REACT_APP_BASH_URL;

function SwapUtill({ item, id, vehicleId, setSuccess, setCustomVariant, setError, setRefresh, refresh, setOpen }) {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "multipart/form-data", role: "admin", };
    const [selectedNumber, setSelectedNumber] = useState('');

    const [valSelectedNumber, setValSelectedNumber] = useState('')

    const [loading, setLoading] = useState(false)


    const handleChange = (event) => {
        setSelectedNumber(event.target.value);
        setValSelectedNumber('')
    };

    // oldDriver: id
    // newDriver: item?.id
    // vehicle: vehicleId

    const handleApprovalOfDriver = async () => {
        if (!selectedNumber) {
            setValSelectedNumber("Select duration")
        } else {
            setLoading(true)
            return await axios.post(`${localUrl}/swap/add-swap`, { driverId: id, newDriverId: item?.id, vehicleId: vehicleId, duration: selectedNumber }, { headers: options }).then((response) => {
                setRefresh(!refresh)
                setLoading(false)
                setOpen(true)
                setCustomVariant("success")
                setError("")
                setSuccess(response?.data?.msg)
            }).catch((error) => {
                setLoading(false)
                setOpen(true)
                setSuccess("")
                setCustomVariant("error")
                setError(error?.response?.data?.msg)
            })
        }
    }


    return (
        <TableRow >
            <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.name}</TableCell>
            <TableCell sx={{ color: "black" }} align="center">{item?.email}{" "}</TableCell>
            <TableCell sx={{ color: "black" }} align="center">{item?.mobile}</TableCell>
            <TableCell sx={{ color: "black" }} align="center">{item?.address}{" "}</TableCell>
            <TableCell sx={{}} align="center">
                <Box sx={{ width: '100%', mb: 1 }}>
                    <FormControl fullWidth error={valSelectedNumber}>
                        <InputLabel id="number-select-label">Select Number</InputLabel>
                        <Select labelId="number-select-label" id="number-select" value={selectedNumber} label="Select Number" onChange={handleChange}>
                            {[1, 2, 3, 4].map((number) => (
                                <MenuItem key={number} value={number}>
                                    {number}
                                </MenuItem>
                            ))}
                        </Select>
                        {valSelectedNumber && <FormHelperText>{valSelectedNumber}</FormHelperText>}
                    </FormControl>
                </Box>
                <Tooltip title="Swap Driver"><Button variant="outlined" color="success" onClick={handleApprovalOfDriver} fullWidth >Swap</Button></Tooltip>
            </TableCell>
        </TableRow>
    )
}

export default SwapUtill