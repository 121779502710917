import { Backdrop, Box, Button, Chip, CircularProgress, duration, Grid, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import MuiAlert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SwapUtill from '../utils/SwapUtill';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const localUrl = process.env.REACT_APP_BASH_URL;

function SwapDriver() {
    const { id } = useParams()
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "multipart/form-data", role: "admin", };

    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState("");
    const [customVariant, setCustomVariant] = useState("error");
    const [refresh, setRefresh] = useState(false);

    const [vehicle, setVehicle] = useState()
    const [oldDriver, setOldDriver] = useState()

    const [drivers, setDrivers] = useState([])
    const [filterData, setFilterData] = useState([])

    const [search, setSearch] = useState('')

    const [newDriver, setNewDriver] = useState('')


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const getVehicleDetails = async () => {
        return await axios.get(`${localUrl}/swap/get-swap/${id}`, { headers: options }).then((response) => {
            setVehicle(response.data.result)
            setOldDriver(response.data.driver)
            setNewDriver(response?.data?.newDriver)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })
    }

    const getAllDriverList = async () => {
        return await axios.get(`${localUrl}/drivers/get-all`).then((response) => {
            setDrivers(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })
    }



    useEffect(() => {
        getVehicleDetails()
        getAllDriverList()
    }, [loading, refresh])

    useEffect(() => {
        const searchData = drivers.filter((item) =>
            item.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]);

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
            </Snackbar>


            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ width: '50%' }}>
                    <Typography variant='h5' component={'div'} sx={{ ml: 2 }}>Vehicle Details</Typography>
                </Box>
                <Box sx={{ width: '50%', ml: 1 }}>
                    <Typography variant='h6' component={'div'}>Vehicle Driver Details</Typography>
                </Box>
            </Box>

            {newDriver && <Chip label="Swaped" variant="outlined" color='success' sx={{ position: 'absolute', right: 10, top: 125, transform: 'rotate(45deg)', }} />}
            <Box sx={{ width: '100%' }}>

                <Paper sx={{ width: '100%', p: 2, mb: 2 }}>
                    <Grid spacing={2} container>
                        <Grid item xs={12} md={6} lg={6} sm={12} sx={{ display: 'flex' }}>
                            <Box sx={{ width: '50%' }}>
                                <Typography>Vehicle Name: </Typography>
                                <Typography>Vehicle Number: </Typography>
                                <Typography>Vehicle Color: </Typography>


                                {/* <Typography>Number of available Seats: {driver?.avilableSeat || ""}</Typography> */}
                                <Typography>Number of Seats </Typography>

                                <Typography>Owner Name </Typography>

                                <Typography>Owner Number </Typography>

                                <Typography>Start Point </Typography>
                            </Box>

                            <Box sx={{ width: '50%' }}>

                                <Typography>: {vehicle?.vehicleName || ""}</Typography>
                                <Typography>: {vehicle?.vehicleNumber || ""}</Typography>
                                <Typography>: {vehicle?.vehicleColor || ""}</Typography>


                                {/* <Typography>Number of available Seats: {driver?.avilableSeat || ""}</Typography> */}
                                <Typography>: {vehicle?.noSeat || ""}</Typography>

                                <Typography>: {vehicle?.vOwnerName || ""}</Typography>

                                <Typography>: {vehicle?.vOwnerPhone == "undefined" ? "" : vehicle?.vOwnerPhone || ""}</Typography>

                                <Typography>: {oldDriver?.area || ""}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6} sm={12} sx={{ display: 'flex' }}>
                            <Box sx={{ width: '50%' }}>
                                <Typography>Name</Typography>
                                {oldDriver?.email && <Typography>Email</Typography>}
                                <Typography>Mobile No.</Typography>
                                <Typography>Address</Typography>
                                <Typography>Area</Typography>
                                <Typography>School Name</Typography>
                                <Typography>Refer Code</Typography>
                            </Box>

                            <Box sx={{ width: '50%' }}>

                                <Typography>: {oldDriver?.name}</Typography>
                                {oldDriver?.email && <Typography>: {oldDriver?.email}</Typography>}
                                <Typography>: {oldDriver?.mobile}</Typography>
                                <Typography>: {oldDriver?.address}</Typography>
                                <Typography>: {oldDriver?.area}</Typography>
                                <Typography>: {oldDriver?.school_name}</Typography>
                                <Typography>: {oldDriver?.refer_code}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>

                {
                    newDriver &&
                    <>
                        <Typography variant='h6' component={'div'}>Swaped Driver Details</Typography>
                        <Paper sx={{ p: 2, mb: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={6} sm={12} sx={{ display: 'flex' }}>
                                    <Box sx={{ width: '50%' }}>

                                        <Typography>Name</Typography>
                                        {oldDriver?.email && <Typography>Email</Typography>}
                                        <Typography>Mobile No.</Typography>
                                        <Typography>Address</Typography>
                                        <Typography>Area</Typography>
                                        <Typography>School Name</Typography>
                                        <Typography>Refer Code</Typography>
                                    </Box>

                                    <Box sx={{ width: '50%' }}>

                                        <Typography>: {newDriver?.name}</Typography>
                                        {newDriver?.email && <Typography>: {newDriver?.email}</Typography>}
                                        <Typography>: {newDriver?.mobile}</Typography>
                                        <Typography>: {newDriver?.address}</Typography>
                                        <Typography>: {newDriver?.area}</Typography>
                                        <Typography>: {newDriver?.school_name}</Typography>
                                        <Typography>: {newDriver?.refer_code}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </>
                }

                <Typography variant='h5' component={'div'} sx={{ ml: 2 }}>Swap With</Typography>
                <TableContainer component={Paper}>
                    <Box sx={{ width: "100%", padding: 2 }}>
                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ width: "40%" }}>
                            <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search Driver by name" id="outlined-start-adornment"
                                InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                            />
                        </Box>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell >Name</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Phone</TableCell>
                                <TableCell align="center">Address</TableCell>
                                <TableCell align="center"> Actions </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterData && filterData.length ? (
                                filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <SwapUtill key={ind} item={item} id={id} vehicleId={vehicle?.id} setSuccess={setSuccess} setCustomVariant={setCustomVariant} setError={setError} setRefresh={setRefresh} refresh={refresh} setOpen={setOpen} />
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography>No Driver data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </div >
    )
}

export default SwapDriver