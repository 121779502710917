import React, { useEffect, useState } from 'react'
import MuiAlert from "@mui/material/Alert";
import { Backdrop, Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import ModaleComponent from '../popup/ModaleComponent';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bashUrl = process.env.REACT_APP_BASH_URL;


function SwapVehicle() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const Navigate = useNavigate();

    const [drivers, setDrivers] = useState([]);
    const [filterdData, setFilterData] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState("");

    const [openModale, setOpenModale] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState({ name: "", id: "", role: "", });

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();


    // get-user-by-role
    const getAllUsers = async () => {
        return await axios.get(`${bashUrl}/vehicleType/get-all-vehicle`, { headers: options }).then((response) => {
            setDrivers(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((err) => {
            setLoading(false)
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
                Navigate("/signin")
            }
            console.log("error on getAllUsers: ", err);
        })
    }

    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = drivers.filter((item) =>
            item?.vehicleNumber?.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]);

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleApprovalOfDriver = async (id, approve) => {
        /* setLoading(true)
        return await axios.put(`${bashUrl}/users/approve-user`, { id: id, approve: approve }).then((response) => {
            setError("")
            setSuccess(response.data.msg)
            setCustomVariant("success")
            setOpen(true)
        }).catch((error) => {
            setSuccess("")
            setError(error.response.data.msg)
            setCustomVariant("error")
            setOpen(true)
            console.log("error on handleApprovalOfDriver: ", error);
        }) */
    }

    useEffect(() => {
        getAllUsers()
    }, [loading, refresh])

    // console.log("vehicle: ", filterdData);


    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <ModaleComponent openModale={openModale} setOpenModale={setOpenModale} data={data} setData={setData} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} refresh={refresh} setRefresh={setRefresh} setOpen={setOpen} />

            <Box sx={{ width: "100%", pr: 2 }}>
                <Box sx={{ width: "100%", paddingY: 2 }}>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ width: "40%" }}>
                        <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search Driver by name" id="outlined-start-adornment"
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                        />
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell >Vehicle Name</TableCell>
                                <TableCell align="center">Vehicle Number</TableCell>
                                <TableCell align="center">Vehicle Type</TableCell>
                                <TableCell align="center">No. of Seat</TableCell>
                                <TableCell align="center"> Available Seat </TableCell>
                                <TableCell align="center"> Actions </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? (
                                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <TableRow key={ind}>
                                            <TableCell sx={{ color: "black" }} component="th" scope="row">{item.vehicleName}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item.vehicleNumber}{" "}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item.vehicleType}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item.noSeat}{" "}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item.avilableSeat}{" "}</TableCell>
                                            <TableCell sx={{}} align="center">
                                                <Link to={`/swap/${item?.userId}`} style={{ textDecoration: 'none' }} >Swap Driver</Link>
                                                {/* <Tooltip title="View Details"><Link to={`details/${item.id}`} style={{ textDecoration: "none" }}><IconButton color="success"><VisibilityIcon /></IconButton>{" "}</Link>{" "}</Tooltip> */}
                                                {/* {item.status ? <Tooltip title="Click To In-Active"><Button variant="outlined" color="success" onClick={() => handleApprovalOfDriver(item.id, false)} >Active{" "}</Button></Tooltip> : <Tooltip title="Click To Active"><Button variant="outlined" color="warning" onClick={() => handleApprovalOfDriver(item.id, true)}>In-Active{" "}</Button></Tooltip>} */}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography>No Vehicle data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </Box>
    )
}

// <Row key={ind} row={item} statusChange={statusChange} deleteDriver={deleteDriver} setOpenModale={setOpenModale} data={data} setData={setData} setOpen={setOpen} />

export default SwapVehicle