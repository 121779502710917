import axios from 'axios';
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client';

const socketUrl = process.env.REACT_APP_SOCKET_URL;
const socket = io(socketUrl);

const bashUrl = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;

const mapContainerStyle = {
    width: '100%',
    height: '75vh'
};

const defaultCenter = {
    lat: 22.828629022443526,
    lng: 86.218885
};

function Tracking() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(true)

    const getAllDrivers = async () => {
        try {
            const response = await axios.get(`${bashUrl}/users/get-user-by-role?role=driver&online=true`, { headers: options });
            // console.log("response: ", response.data.result);

            setDrivers(response.data.result);
            setLoading(false);
        } catch (error) {
            console.log("error: ", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllDrivers();
    }, []);


    


    return (
        <div>Tracking</div>
    )
}

export default Tracking