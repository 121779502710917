import React, { act, useEffect, useState } from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Tax from './Tax';
import Home from './Home';
import Driver from './Driver';
import VehicleType from './VehicleType';
import School from './School';

import { FaHome } from "react-icons/fa";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Snackbar, Tooltip } from '@mui/material';
import TimeToLeaveRoundedIcon from "@mui/icons-material/TimeToLeaveRounded";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import FamilyRestroomRoundedIcon from "@mui/icons-material/FamilyRestroomRounded";
import { GiBoneGnawer, GiTeacher } from "react-icons/gi";
import SchoolIcon from "@mui/icons-material/School";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { PiMapPinSimpleAreaBold } from "react-icons/pi";
import '@fontsource/roboto/700.css';
import Address from './Address';
import AddSchool from '../components/AddSchool';
import UpdatePrinciple from '../components/UpdatePrinciple';
import DriverDetails from '../view/DriverDetails';
import Parent from './Parent';
import NoCrashIcon from "@mui/icons-material/NoCrash";
import DriverAttendance from './DriverAttenadance';
import BookingDetails from '../view/BookingDetails';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Leave from './Leave';
import { IoMdHelpCircleOutline } from "react-icons/io";
import { MdPostAdd } from "react-icons/md";
import Support from './Support';
import Banner from './Banner';
import ParentView from '../view/ParentView';
import StudentDetails from '../view/StudentDetails';
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import Booking from './Booking';
import Teacher from './Teacher';
import UpdateTeacher from '../components/UpdateTeacher';
import { FaCarCrash } from "react-icons/fa";
import BreakDown from './BreakDown';
import Notification from './Notification';
import { FaCcAmazonPay } from "react-icons/fa";
import { FaAmazonPay } from "react-icons/fa";
import Payment from './Payment';
import { generateToken, messaging } from '../notifications/firebase';
import { onMessage } from 'firebase/messaging';
import MuiAlert from '@mui/material/Alert';
import MakePayment from '../components/MakePayment';
import WithdrawalRequest from './WithdrawalRequest';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import PassengerPayemnt from './PassengerPayemnt';
import { TbCreditCardPay } from "react-icons/tb";
import AddPassengerMakePayment from '../components/AddPassengerMakePayment';
import Agent from './Agent';
import { MdAdminPanelSettings } from "react-icons/md";
import AddAgent from '../components/AddAgent';
import BookingPayment from './BookingPayment';
import { IoSettings } from "react-icons/io5";
import Setting from './Setting';
import { TbReportMoney } from "react-icons/tb";
import { TbReportMedical } from "react-icons/tb";
import ReportCollection from './ReportCollection';
import ReportCommission from './ReportCommission';
import Refer from './Refer';
import { VscReferences } from "react-icons/vsc";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TbArrowsExchange } from "react-icons/tb";
import AddressChangeRequest from './AddressChangeRequest';
import TeacherAttendance from '../view/TeacherAttendance';
import { BsPinMap } from "react-icons/bs";
import Tracking from './Tracking';
import { LuKeyRound } from "react-icons/lu";
import ResetPassword from './ResetPassword';
import StudentBooking from '../view/StudentBooking';
import ReferDetails from '../view/ReferDetails';
import MenuListAlert from '../utils/MenuListAlert';
import { FaBell } from "react-icons/fa";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { FaBellSlash } from "react-icons/fa";
import SwapVehicle from './SwapVehicle';
import SwapDriver from '../components/SwapDriver';

const drawerWidth = 240;

const activeText = "rgb(25, 118, 210)"
const activeBg = "lightgray"

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const container = window !== undefined ? () => window().document.body : undefined;

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Dashboard({ user }) {
    const navigate = useNavigate();

    const [success, setSuccess] = useState("")
    const [snakOpen, setSnakOpen] = useState(false)
    const [showError, setShowError] = useState(false)

    const [menu, setMenu] = useState({
        name: "Dashboard",
        active: 0
    })

    const [agentMenu, setAgentMenu] = useState({
        name: 'Payment',
        active: 0
    })

    // console.log("user: ", user?.role);

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handlChange = (name, active) => {
        setMenu({ name: name, active: active })
    }

    const handleLogout = () => {
        localStorage.removeItem("admin-token");
        navigate("/signin");
    };


    useEffect(() => {
        generateToken()
        onMessage(messaging, (payload) => {
            setSuccess(payload.notification.title)
            setSnakOpen(true)
            // console.log("payload: ", payload);
            if (payload.data.type == 'break-down') {
                // console.log("breakdown alert");
                setShowError(true)
            }
        })
    }, [])

    const handleCloseBreakDown = async () => {
        setShowError(false)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Snackbar open={snakOpen} autoHideDuration={6000} onClose={() => setSnakOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
                <Alert onClose={() => setSnakOpen(false)} severity={"success"} sx={{ width: '100%' }}>
                    {success ? success : ""}
                </Alert>
            </Snackbar>


            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ marginRight: 5, ...(open && { display: 'none' }), }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">{menu.name}</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Link to={'notifications'} style={{ textDecoration: 'none', color: 'black' }}>
                        <Tooltip title="Notificaitons" >

                            <IconButton onClick={() => setMenu({ name: 'Notifications', active: 3 })}>
                                {/* <Badge badgeContent={4} color="error"> */}
                                <FaBell color='white' />
                                {/* </Badge> */}
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <Tooltip title="Logout">
                        <IconButton onClick={handleLogout}>
                            <LogoutRoundedIcon sx={{ color: "white" }} />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <img src='/images/logo.svg' alt='logo' style={{ width: '100%', height: 50 }} />
                    <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
                </DrawerHeader>
                <Divider />

                {user?.role == 'admin' && <>
                    <List>
                        {/* home */}
                        <Link to={"/"} onClick={() => handlChange("Dashboard", 0)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Dashboard" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 0 ? activeBg : "", color: menu.active === 0 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <FaHome size={24} color={menu.active === 0 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Dashboard"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* driver */}
                        <Link to={"/driver"} onClick={() => handlChange("Driver", 1)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Driver" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 1 ? activeBg : "", color: menu.active === 1 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <TimeToLeaveRoundedIcon size={24} sx={{ color: menu.active === 1 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Driver"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* driver attendance */}
                        <Link to={"/driver/attendance"} onClick={() => handlChange("Driver Attendance", 2)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Driver Attendance" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 2 ? activeBg : "", color: menu.active === 2 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <NoCrashIcon size={24} sx={{ color: menu.active === 2 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Driver Attendance"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* Parent */}
                        <Link to={"/parent"} onClick={() => handlChange("Parent", 4)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Parent" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 4 ? activeBg : "", color: menu.active === 4 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <FamilyRestroomRoundedIcon size={24} sx={{ color: menu.active === 4 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Parent"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* Student Leave */}
                        <Link to={"/leave"} onClick={() => handlChange("Leave", 5)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Leave" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 5 ? activeBg : "", color: menu.active === 5 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <EventBusyIcon size={24} sx={{ color: menu.active === 5 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Leave"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* Teacher */}
                        <Link to={"/teacher"} onClick={() => handlChange("Teacher", 6)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Teacher" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 6 ? activeBg : "", color: menu.active === 6 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <GiTeacher size={24} color={menu.active === 6 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Teacher"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* school */}
                        <Link to={"/school"} onClick={() => handlChange("Schools", 7)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Schools" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 7 ? activeBg : "", color: menu.active === 7 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <SchoolIcon size={24} sx={{ color: menu.active === 7 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Schools"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* <Accordion >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                    <VscReferences size={24} sx={{ color: menu.active === 24 || menu.active === 25 || menu.active === 26 ? activeText : "" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Refer"} sx={{ opacity: open ? 1 : 0, marginRight: 5, ...(open ? { display: 'block' } : { display: 'none' }), }} />
                            </AccordionSummary>
                            <AccordionDetails>

                                <Link to={"/refer-parent"} onClick={() => handlChange("Refer Parent", 24)} style={{ textDecoration: 'none' }}>
                                    <Tooltip title="Refer Parent" placement="right">
                                        <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 24 ? activeBg : "", color: menu.active === 24 ? activeText : "black" }}>
                                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                                    <VscReferences size={24} color={menu.active === 24 ? activeText : ""} />
                                                </ListItemIcon>
                                                <ListItemText primary={"Refer Parent"} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Tooltip>
                                </Link>


                                <Link to={"/refer-driver"} onClick={() => handlChange("Refer Driver", 25)} style={{ textDecoration: 'none' }}>
                                    <Tooltip title="Refer Driver" placement="right">
                                        <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 25 ? activeBg : "", color: menu.active === 25 ? activeText : "black" }}>
                                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                                    <VscReferences size={24} color={menu.active === 25 ? activeText : ""} />
                                                </ListItemIcon>
                                                <ListItemText primary={"Refer Driver"} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Tooltip>
                                </Link>


                                <Link to={"/refer-teacher"} onClick={() => handlChange("Refer Teacher", 26)} style={{ textDecoration: 'none' }}>
                                    <Tooltip title="Refer Teacher" placement="right">
                                        <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 26 ? activeBg : "", color: menu.active === 26 ? activeText : "black" }}>
                                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                                    <VscReferences size={24} color={menu.active === 26 ? activeText : ""} />
                                                </ListItemIcon>
                                                <ListItemText primary={"Refer Teacher"} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Tooltip>
                                </Link>
                            </AccordionDetails>
                        </Accordion> */}


                        {/* refer parent*/}
                        <Link to={"/refer-parent"} onClick={() => handlChange("Refer Parent", 24)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Refer Parent" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 24 ? activeBg : "", color: menu.active === 24 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <VscReferences size={24} color={menu.active === 24 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Refer Parent"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* refer driver*/}
                        <Link to={"/refer-driver"} onClick={() => handlChange("Refer Driver", 25)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Refer Driver" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 25 ? activeBg : "", color: menu.active === 25 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <VscReferences size={24} color={menu.active === 25 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Refer Driver"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* refer teacher*/}
                        <Link to={"/refer-teacher"} onClick={() => handlChange("Refer Teacher", 26)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Refer Teacher" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 26 ? activeBg : "", color: menu.active === 26 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <VscReferences size={24} color={menu.active === 26 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Refer Teacher"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>
                    </List>


                    <Divider />
                    <List>
                        {/* booking */}
                        <Link to={"/booking"} onClick={() => handlChange("Booking", 15)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Booking" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 15 ? activeBg : "", color: menu.active === 15 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <FaMoneyBillTrendUp size={24} color={menu.active === 15 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Booking"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* support */}
                        <Link to={"/support"} onClick={() => handlChange("Support Ticket", 10)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Support Ticket" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 10 ? activeBg : "", color: menu.active === 10 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <IoMdHelpCircleOutline size={24} color={menu.active === 10 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Support Ticket"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* vehicle break down */}
                        <Link to={"/break"} onClick={() => handlChange("Break Down", 16)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Break Down" placement="right">
                                <MenuListAlert menu={menu} open={open} showError={showError} setShowError={setShowError} />
                            </Tooltip>
                        </Link>

                        {/* swap vehicle */}
                        <Link to={"/swap"} onClick={() => handlChange("Swap Vehicle", 23)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Swap Vehicle" placement="right">
                                <MenuListAlert menu={menu} open={open} showError={showError} setShowError={setShowError} />
                            </Tooltip>
                        </Link>
                        {/* <Link to={"/break"} onClick={() => handlChange("Break Down", 16)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Break Down" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 16 ? activeBg : "", color: menu.active === 16 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <FaCarCrash size={24} color={menu.active === 16 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Break Down"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link> */}


                        {/* payment */}
                        <Link to={"/payment"} onClick={() => handlChange("Payment", 17)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Payment" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 17 ? activeBg : "", color: menu.active === 17 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <FaAmazonPay size={24} color={menu.active === 17 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Payment"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* passenger payment */}
                        <Link to={"/passenger"} onClick={() => handlChange("Cash Collection", 19)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Cash Collection" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 19 ? activeBg : "", color: menu.active === 19 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <TbCreditCardPay size={24} color={menu.active === 19 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Cash Collection"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* withdrawal */}
                        <Link to={"/withdrawal"} onClick={() => handlChange("Withdrawal Request", 18)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Withdrawal Request" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 18 ? activeBg : "", color: menu.active === 18 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <FaMoneyBillTransfer size={24} color={menu.active === 18 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Withdrawal Request"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* Area */}
                        <Link to={"/area"} onClick={() => handlChange("Area", 11)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Area" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 11 ? activeBg : "", color: menu.active === 11 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <PiMapPinSimpleAreaBold size={24} color={menu.active === 11 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Area"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* change address request */}
                        <Link to={"/change-address"} onClick={() => handlChange("Address Change Request", 25)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Address Change Request" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 25 ? activeBg : "", color: menu.active === 25 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <TbArrowsExchange size={24} color={menu.active === 25 ? activeText : ""} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Address Change Request"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* vehicle type */}
                        <Link to={"/vehilceType"} onClick={() => handlChange("Vehicle Type", 12)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Vehicle Type" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 12 ? activeBg : "", color: menu.active === 12 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <AirportShuttleIcon size={24} sx={{ color: menu.active === 12 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Vehicle Type"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* benner  */}
                        <Link to={"/benner"} onClick={() => handlChange("Banner", 14)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Banner" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 14 ? activeBg : "", color: menu.active === 14 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <MdPostAdd size={24} sx={{ color: menu.active === 14 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Banner"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* <Link to={"/benner"} onClick={() => handlChange("Banner", 14)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Banner" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 14 ? activeBg : "", color: menu.active === 14 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <MdPostAdd size={24} sx={{ color: menu.active === 14 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Banner"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link> */}


                        {/* agent  */}
                        <Link to={"/agent"} onClick={() => handlChange("Collection Agent", 20)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Collection Agent" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 20 ? activeBg : "", color: menu.active === 20 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <MdAdminPanelSettings size={24} sx={{ color: menu.active === 20 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Collection Agent"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* setting  */}
                        <Link to={"/setting"} onClick={() => handlChange("Setting", 21)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Setting" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 21 ? activeBg : "", color: menu.active === 21 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <IoSettings size={24} sx={{ color: menu.active === 21 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Setting"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* report collection  */}
                        <Link to={"/report-collection"} onClick={() => handlChange("Collection Report", 22)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Collection Report" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 22 ? activeBg : "", color: menu.active === 22 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <TbReportMoney size={24} sx={{ color: menu.active === 22 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Collection Report"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* tracking  */}
                        <Link to={"/tracking"} onClick={() => handlChange("Tracking", 28)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Tracking" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 28 ? activeBg : "", color: menu.active === 28 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <BsPinMap size={24} sx={{ color: menu.active === 28 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Tracking"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* notifications */}
                        <Link to={"/notifications"} onClick={() => handlChange("Notifications", 3)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Notifications" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 3 ? activeBg : "", color: menu.active === 3 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <NotificationsIcon size={26} sx={{ color: menu.active === 3 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Notifications"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* reset password  */}
                        <Link to={"/reset-password"} onClick={() => handlChange("Reset Password", 27)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Reset Password" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 27 ? activeBg : "", color: menu.active === 27 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <LuKeyRound size={24} sx={{ color: menu.active === 27 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Reset Password"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>


                        {/* report commission  */}
                        {/* <Link to={"/report-commission"} onClick={() => handlChange("Commission Report", 23)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Commission Report" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 23 ? activeBg : "", color: menu.active === 23 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <TbReportMedical size={24} sx={{ color: menu.active === 23 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Commission Report"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link> */}

                        {/* Tax */}
                        {/* <Link to={"/tax"} onClick={() => handlChange("Tax", 13)} style={{ textDecoration: 'none' }}>
                        <Tooltip title="Tax" placement="right">
                            <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 13 ? activeBg : "", color: menu.active === 13 ? activeText : "black" }}>
                                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                        <CurrencyRupeeIcon size={24} sx={{ color: menu.active === 13 ? activeText : "" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Tax"} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    </Link> */}
                    </List>
                </>}

                {user?.role == 'agent' && <>
                    <List>
                        {/* agent booking payment  */}
                        <Link to={"/"} onClick={() => setAgentMenu({ name: 'Payment', active: 0 })} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Payment" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: agentMenu.active === 0 ? activeBg : "", color: agentMenu.active === 0 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <FaMoneyBillTransfer size={24} sx={{ color: agentMenu.active === 0 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Payment"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* reset password  */}
                        <Link to={"/reset-password"} onClick={() => handlChange("Reset Password", 1)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Reset Password" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 1 ? activeBg : "", color: menu.active === 1 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <LuKeyRound size={24} sx={{ color: menu.active === 1 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Reset Password"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>

                        {/* notifications */}
                        <Link to={"/notifications"} onClick={() => handlChange("Notifications", 3)} style={{ textDecoration: 'none' }}>
                            <Tooltip title="Notifications" placement="right">
                                <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 3 ? activeBg : "", color: menu.active === 3 ? activeText : "black" }}>
                                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                                            <NotificationsIcon size={26} sx={{ color: menu.active === 3 ? activeText : "" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={"Notifications"} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>
                        </Link>
                    </List>
                </>}
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Routes>
                    {user?.role == 'admin' && <>
                        <Route path='/' element={<Home menu={menu} setMenu={setMenu} showError={showError} />} />
                        <Route path='/driver' element={<Driver />} />
                        <Route path="/driver/details/:id" element={<DriverDetails admin={user} />} />
                        <Route path="/driver/bookingDetails/:id" element={<BookingDetails admin={user} />} />

                        <Route path="/driver/attendance" element={<DriverAttendance admin={user} />} />

                        <Route path='/parent' element={<Parent admin={user} />} />
                        <Route path='/parent/details/:id' element={<ParentView admin={user} />} />
                        <Route path='/student/details/:id' element={<StudentDetails admin={user} />} />
                        <Route path='/student/booking/:id' element={<StudentBooking admin={user} />} />

                        <Route path='/teacher' element={<Teacher admin={user} />} />
                        <Route path='/teacher/attendance/:id' element={<TeacherAttendance admin={user} />} />
                        <Route path='/user/update/:id' element={<UpdateTeacher admin={user} />} />

                        <Route path='/leave' element={<Leave admin={user} />} />

                        <Route path='/school' element={<School />} />
                        <Route path='/school/addSchool' element={<AddSchool />} />
                        <Route path="/school/updateSchool/:id" element={<AddSchool admin={user} />} />
                        <Route path="/school/updatePrincipal/:id" element={<UpdatePrinciple admin={user} />} />

                        <Route path='/booking' element={<Booking admin={user} />} />

                        <Route path='/support' element={<Support admin={user} />} />

                        <Route path='/break' element={<BreakDown admin={user} />} />

                        <Route path='/swap' element={<SwapVehicle admin={user} />} />
                        <Route path='/swap/:id' element={<SwapDriver admin={user} />} />

                        <Route path='/payment' element={<Payment admin={user} />} />
                        <Route path='/make-payment' element={<MakePayment admin={user} />} />


                        <Route path='/passenger' element={<PassengerPayemnt admin={user} />} />
                        <Route path='/passenger/make-payment' element={<AddPassengerMakePayment admin={user} />} />

                        <Route path='/withdrawal' element={<WithdrawalRequest admin={user} />} />


                        <Route path='/area' element={<Address admin={user} />} />

                        <Route path='/vehilceType' element={<VehicleType admin={user} />} />

                        <Route path='/benner' element={<Banner admin={user} />} />

                        <Route path='/tax' element={<Tax admin={user} />} />

                        <Route path='/refer-parent' element={<Refer admin={user} refRole={"parent"} />} />
                        <Route path='/refer-driver' element={<Refer admin={user} refRole={"driver"} />} />
                        <Route path='/refer-teacher' element={<Refer admin={user} refRole={"teacher"} />} />
                        <Route path='/refer-view/:id' element={<ReferDetails admin={user} />} />

                        <Route path='/setting' element={<Setting admin={user} />} />


                        <Route path='/agent' element={<Agent admin={user} />} />
                        <Route path='/agent/add' element={<AddAgent admin={user} />} />
                        <Route path='/agent/update/:id' element={<AddAgent admin={user} />} />

                        <Route path='/report-collection' element={<ReportCollection admin={user} />} />

                        {/* <Route path='/report-commission' element={<ReportCommission admin={user} />} /> */}


                        <Route path='/change-address' element={<AddressChangeRequest admin={user} />} />

                        <Route path='/tracking' element={<Tracking admin={user} />} />
                        <Route path='/reset-password' element={<ResetPassword admin={user} />} />


                        <Route path='/notifications' element={<Notification admin={user} />} />

                    </>}
                    {user?.role == 'agent' && <>
                        {/* <Route path='/payment' element={<PassengerPayemnt admin={user} />} /> */}
                        <Route path='/' element={<PassengerPayemnt admin={user} />} />
                        <Route path='/reset-password' element={<ResetPassword admin={user} />} />
                        <Route path='/notifications' element={<Notification admin={user} />} />
                    </>}
                </Routes>
            </Box>
        </Box>
    )
}

export default Dashboard